import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { css, Global } from '@emotion/react'
import GlobalStyles from '~styles/global'
import Header from '~components/Header'
import Cart from '~components/Cart'
import Footer from '~components/Footer'
import smoothscroll from 'smoothscroll-polyfill'
import { mobile } from '~styles/global'
import Transition from '~components/Transition'
import { useSiteState, useCart } from '../context/siteContext'
import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import '~styles/static.css'
import useFontsReady from '~utils/useFontsReady'

const Layout = ({ children }) => {

	const [siteState, setSiteState] = useSiteState()
	const { closeCart } = useCart()
	const location = useLocation()
	const fontsReady = useFontsReady()

	useEffect(() => {
		smoothscroll.polyfill()
	}, [])

	// Close the cart whenever the route changes
	useEffect(() => {
		closeCart()
		// eslint-disable-next-line
  }, [location])

	return (
		<>
			{siteState.menuOpen &&
				<Global
					styles={css`
						body, main{
							max-height: 100vh;
							overflow-y: hidden;
						}
					`}
				/>
			}
			{!fontsReady &&
				<Global
					styles={css`
						body {
							opacity: 0;
						}
					`}
				/>
			}
			<GlobalStyles />
			<Header />
			<Cart open={siteState.cartOpen} />
			<main>
				<Transition>
					<Wrap >
						{children}
					</Wrap>
				</Transition>
			</main>
			<Overlay 
				onClick={() => setSiteState(prevState => ({
					...prevState,
					cartOpen: false,
					menuOpen: false
				}))}
				css={css`
          pointer-events: ${(siteState.cartOpen || siteState.menuOpen)? 'all' : 'none'};
      `}/>
		</>
	)
}
const Wrap = styled.div`
	min-height: calc(100vh);
	display: flex;
	flex-direction: column;
	padding-top: var(--xl);
	box-sizing: border-box;
	${mobile}{
		min-height: calc(100vh);
		padding-top: var(--m);
	}
`
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
	z-index: 11;
`

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
