import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'

const SanityLink = ({ className, link, children, onMouseEnter, onMouseLeave }) => {
	if(link?.linkType === 'internal'){
		return (
			<Link 
				className={className} 
				to={resolveLink(link?.document)}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{children}
			</Link>
		)
	} else {
		return (
			<a 
				href={link?.url} 
				target={link?.blank ? '_blank' : '_self'} 
				rel='noreferrer' 
				className={className}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{children}
			</a>
		)
	}
}

SanityLink.propTypes = {
	className: PropTypes.string,
	link: PropTypes.object,
	children: PropTypes.node,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func
}

export default SanityLink