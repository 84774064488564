import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp

const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
  body {
    font-family: 'Rebond Grotesque', sans-serif;
		background-color: var(--white);
		font-feature-settings: 'ss02';
		color: var(--black);
		font-weight: 400;
    font-size: 23px;
		line-height: 1.4;
		/* letter-spacing: 0.02em; */
		${tablet} {
      font-size: 18px;
      line-height: 1.23;
			letter-spacing: 0.0em;
    } 
  }

  :root{
    --white: #ffffff;
    --black: #000000;
		--feature: #000000;
		--cream: #f7f4ef;
		--xxxxs: 6px;
    --xxxs: 8px;
    --xxs: 10px;
		--xs: 14px;
		--s: 18px;
		--m: 20px;
    --l: 22px;
    --xl: 26px;
    --xxl: 30px;
    --xxxl: 56px; 
    --xxxxl: 110px;
		--large: 38px;
	}

  ${tablet}{
    :root{
			--xxxxs: 6px;
			--xxxs: 6px;
			--xxs: 8px;
			--xs: 12px;
			--s: 14px;
			--m: 16px;
			--l: 18px;
			--xl: 22px;
			--xxl: 32px; 
			--xxxl: 48px; 
			--xxxxl: 94px;
			--large: 22px;
    }
  }

  p{
    margin-bottom: 1.2em;
    &:last-child{
      margin-bottom: 0;
    }
  }

  h1,
  .h1 {
    font-size: 55px;
    /* letter-spacing: -0.032em; */
		line-height: 1.09;
    ${tablet} {
      font-size: 36px;
			/* letter-spacing: 0.014em; */
			line-height: 1;
    }
  }
	h2,
  .h2 {
    font-size: 23px;
    line-height: 1.27;
		/* letter-spacing: 0.02em; */
    ${tablet} {
      font-size: 22px;
      line-height: 1.17;
			/* letter-spacing: 0.03em; */
    }
  }
	h3,
	.h3 {
		${tablet} {
      font-size: 14px;
			/* letter-spacing: 0.04em; */
    } 
	}
	.small{
		font-size: 16px;
		line-height: 1.4;
		/* letter-spacing: 0.044em; */
		${tablet}{
			font-size: 12px;
			font-weight: 500;
		}	
	}
	.hightlight{
		color: var(--feature);
	}
	ul {
		margin-left: 18px;
		margin-bottom: 1.2em;
		li {
			padding-left: 20px;
			margin: 0px;
			${tablet}{
				padding-left: 10px;
			}
		}
	}
	button{
		color: inherit;
	}

	strong{
		font-weight: 500;
	}

	p a{
		text-decoration: underline;
	}
	
	input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: var(--white);
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000s ease-in-out 0s;
	}
`

export const PageSection = styled.div`
display: grid;
grid-column: span 4;
grid-template-columns: repeat(4, 1fr);
grid-column-gap: 26px;
${mobile}{
	grid-template-columns: repeat(1, 1fr);
	grid-column-gap: 0px;
}

`

const reset = css`
  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
    box-shadow: 0 0 0 500px white inset !important;
  }
`

export default GlobalStyles
