import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SanityLink from '~components/SanityLink'

const Button = ({ className, link, children, size, onClick }) => {

	const buttonStyles = css`
		font-weight: ${size === 'large' ? '500' : '700'};
		border-top: ${size === 'large' ? 'none' : '1px solid'};
		&:last-of-type{
			border-bottom: ${size === 'large' ? 'none' : '1px solid'};
		}
		padding: ${size === 'large' ? '0' : 'var(--xxxs) 0'};
		${mobile}{
			padding: ${size === 'large' ? '0' : '10px 0'};
		}
	`

	return (
		link ? 
			<LinkWrap 
				className={`${className} ${size === 'large' ? 'h1' : 'h3'}`} 
				link={link} 
				css={buttonStyles}
			>
				<Arrow
					css={css`
					margin-right: ${size === 'large' ? 'var(--s)' : 'var(--xxxs)'};
				`}
				>→</Arrow>{children}
			</LinkWrap>
			: 
			<ButtonWrap 
				className={`${className} ${size === 'large' ? 'h1' : 'h3'}`} 
				onClick={onClick} 
				css={buttonStyles}
			>
				<Arrow
					css={css`
						margin-right: ${size === 'large' ? 'var(--s)' : 'var(--xxxs)'};
			`}
				>→</Arrow>{children}
			</ButtonWrap>
	)
}

const LinkWrap = styled(SanityLink)`
	display: block;
`
const ButtonWrap = styled.button`
	display: block;
	width: 100%;
	text-align: left;
`
const Arrow = styled.span`
	font-weight: 500;
	display: inline-block;
`

Button.propTypes = {
	className: PropTypes.string,
	link: PropTypes.object,
	children: PropTypes.string,
}

export default Button