import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mobile, tablet } from '~styles/global'
import axios from 'axios'

const NewsletterSignupForm = ({ className, successMessage }) => {
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)
	const [failure, setFailure] = useState(false)

 
	const sendToEmailOctopus = async (email) => {
		await axios({
			method: 'POST',
			url: 'https://www.jackywinter.com/api/email-octopus',
			data: {
				email_address: email,
			},
		}).then(res => {
			if (res.data?.status === 'success' || (res.data?.error?.config?.data.includes('SUBSCRIBED'))) {
				setSuccess(true)
			} else if (res.data?.status === 'error') {
				setFailure(true)
			}
		})
	}


	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		sendToEmailOctopus(values.email)
	}

	const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<Success css={css`
        opacity: ${success ? '1' : '0'};
        pointer-events: ${success ? 'all' : 'none'};
      `}>
				{successMessage}
			</Success>
			<FormWrap css={css`
        opacity: ${success ? '0' : '1'};
        pointer-events: ${success ? 'none' : 'all'};
        `}>
				<Form onSubmit={e => validate(e)} noValidate>
					<Input
						type="email"
						name="email"
						placeholder="Email*"
						css={css`
							color: var(--white);
							::placeholder{
								color: ${errorFields.includes('email') ? 'var(--red)' : 'var(--white)'};
							}    
          `}	
						onChange={e => setValues({...values, email: e.target.value})}
						onBlur={() => setFailure(false)}
					/>
					{errors?.length > 0 &&
            <Errors>
            	{errors.map(error => <p>{error.message}</p>)}
            </Errors>
					}
					{failure &&
            <Errors>
            	Please try again
            </Errors>
					}
					<Submit type="submit">
						<Arrow>→</Arrow>Submit
					</Submit>
				</Form>
			</FormWrap>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
`

const FormWrap = styled.div`
  position: relative;
  transition: opacity 0.3s;
	width: 100%;
`
const Form = styled.form`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr max-content;
	align-items: center;
	border-top: 1px solid var(--white);
	border-bottom: 1px solid var(--white);
	height: 44px;
	${tablet}{
		height: 31px;
	}
`
const Input = styled.input`
	height: 100%;
	border: none;
	background: transparent;
	font-size: inherit;
	font-family: inherit;
	&:-webkit-autofill {
		box-shadow: none !important;
		&:active, &:focus, &:hover {
			box-shadow: none !important;
		}
	}
	&:-internal-autofill-selected {
		background-color: transparent !important;
	}
`
const Submit = styled.button`
	font-weight: 600;
	position: relative;
	top: -1px;
`
const Errors = styled.div`
	position: absolute;
  bottom: calc(100% + 10px);

`

const Success = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s 0.3s;
	font-weight: 600;
`
const Arrow = styled.span`
	display: inline-block;
	margin-right: var(--xs);
	position: relative;
	top: -1px;
	${mobile}{
		margin-right: var(--xxs);
	}
`
NewsletterSignupForm.propTypes = {
	className: PropTypes.string,
	successMessage: PropTypes.string,
	showName: PropTypes.bool,
}

export default NewsletterSignupForm
