import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import RichText from '~components/RichText'
import Image from '~components/Image'

const InlineImage = ({ className, content }) => {
	return (
		<Wrap className={className}>
			<MainImage image={content}/>
			<Caption className='small'>
				{content.alt}
			</Caption>
		</Wrap>
	)
}

const Wrap = styled.div`
`
const MainImage = styled(Image)`
	margin-bottom: var(--xxs);
`
const Caption = styled.div`
	color: var(--feature);
`
InlineImage.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default InlineImage