import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'

const Space = ({ className, size }) => {

	const height = () => {
		if(size === 'large'){
			return '102px'
		}
		if(size === 'medium'){
			return '56px'
		}
		if(size === 'small'){
			return '12px'
		}
	}

	const mobileHeight = () => {
		if(size === 'large'){
			return '62px'
		}
		if(size === 'medium'){
			return '34px'
		}
		if(size === 'small'){
			return '10px'
		}
	}


	return (
		<Spacer className={className} 
			css={css`
				margin: ${height()} 0;
				${mobile}{
					margin: ${mobileHeight()} 0;
				}
		`}/>
	)
}

const Spacer = styled.div`
	height: 1px;
	${mobile}{
		height: 1px;
	}
`

Space.propTypes = {
	className: PropTypes.string,
	size: PropTypes.string,
}

export default Space