const resolveLink = (doc) => {
	if(!doc) return null
	const type = doc._type
	const slug = doc.slug?.current

	switch (type) {
	case 'page':
		return `/${doc.parentPlace?.slug?.current}/${slug}`
	default:
		return `/${slug}`
	}

}

export default resolveLink
