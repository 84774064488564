import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import RichText from '~components/RichText'

const Accordion = ({ className, content }) => {
	const [open, setOpen] = useState(false)
	return (
		<Wrap className={className} >
			<Title onClick={() => setOpen(!open)}>
				<Icon>{open ? '—' : '+'}</Icon>{content.title}
			</Title>
			<SlideDown 
				className={'my-dropdown-slidedown'} 
				css={css`
          transition: 0.4s ease-in-out;
      `}>
				{open ? 
					<Text>
						<RichText content={content.text}/>
					</Text>
					: null
				}
			</SlideDown>
		</Wrap>
	)
}

const Wrap = styled.div`
	margin-top: -1px;
`
const Title = styled.div`
	cursor: pointer;
	color: var(--feature);
	font-weight: 700;
	border-top: 1px solid var(--feature);
	border-bottom: 1px solid var(--feature);
	padding: calc(var(--xxxs) + 1px) 0 var(--xxxs);

	${mobile}{
		padding: calc(4px + 1px) 0 4px;
	}
`
const Icon = styled.span`
	display: inline-block;
	margin-right: var(--xl);
	width: 15px;
`
const Text = styled.div`
	padding: var(--xs) 0 var(--xxl);
`

Accordion.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default Accordion