import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet, PageSection } from '~styles/global'
import * as Flickity from 'react-flickity-component'
import '~styles/flickity.css'
import Image from '~components/Image'
import { LargeArrow } from '~components/Svg'
import {Rarr} from '~components/Svg'
import RichText from '~components/RichText'
import { AnimatePresence, motion } from 'framer-motion'
import useWindow from '~utils/useWindow'

class ImageSliderBlock extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentSlide: this.props.slideToShow ?? 0,
    }
  }

  componentDidMount = () => {
    // You can register events in componentDidMount hook
    this.flkty.on('change', () => {
      this.change()
      // this.props.onSlide(this.flkty.selectedIndex)
    })
  }

  change() {
    this.setState({
      currentSlide: this.flkty.selectedIndex
    })
  }

  render() {

		const flickityOptions = {
			prevNextButtons: true,
			pageDots: false,
			adaptiveHeight: false,
			draggable: this.props.window.innerWidth <= 1024,
			wrapAround: false,
			initialIndex: 0,
			cellAlign: 'left',
			freeScroll: false,
			contain: true,
			arrowShape: 'M8.38186e-06 31.977L32.009 64L35.2536 60.7516L8.83643 34.3038L92 34.3038L92 29.6962L8.7904 29.6962L35.1846 3.27142L31.94 -4.13017e-07L0.0460324 31.9309L8.38186e-06 31.977Z'
		}

		return (
			<>
				<Wrap className={this.props.className}>
					<Slider>
						<Flickity
							flickityRef={c => this.flkty = c}
							className={`carousel`} 
							elementType={'div'} 
							options={flickityOptions} // takes flickity options {}
							disableImagesLoaded={false} // default false
							reloadOnUpdate={false} // default false
							static // default false
							css={css`
								.flickity-prev-next-button {
									width: 50%;
								}
								.flickity-prev-next-button.previous{
									cursor: url('/images/Larr.png'), auto;
									cursor: -webkit-image-set(url("/images/Larr.png") 1x, url("/images/Larr@2x.png") 2x) 0 0, pointer; 
								}
								.flickity-prev-next-button.next{
									cursor: url('/images/Rarr.png'), auto;
									cursor: -webkit-image-set(url("/images/Rarr.png") 1x, url("/images/Rarr@2x.png") 2x) 0 0, pointer; 
								}
								${mobile}{
									.flickity-prev-next-button {
										background-color: ${this.props.highlight ?? 'var(--feature)'};
										width: 36px;
										height: 36px;
									}
									.flickity-prev-next-button.next, 
									.flickity-prev-next-button.previous {
										cursor: pointer;
									}
								}
							`}
						>
							{this.props.content?.images?.map(image => (
								<Slide 
									key={image.asset?._id} 
									image={image} 
								/>
							))}
						</Flickity>
					</Slider>
					<CaptionContainer className='small' >
						<Counter className='small'>
							{this.state.currentSlide + 1} / {this.props.content?.images?.length}
						</Counter>
						<Caption>
							<AnimatePresence exitBeforeEnter>
								<motion.div 
									key={this.state.currentSlide}
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{ duration: 0.3}}
								>
									<RichText content={this.props.content?.images[this.state.currentSlide].caption}/>
								</motion.div>
							</AnimatePresence>
						</Caption>
					</CaptionContainer>
				</Wrap>
			</>
		)
	}
}

const ImageSlider = (props) => {
	const window = useWindow()
	return <ImageSliderBlock window={window} {...props}/>
} 
const Wrap = styled(PageSection)`
	margin: var(--large) 0;
`
const Slider = styled.div`
	position: relative;
	grid-column: span 4;
	margin-bottom: var(--s);
	${mobile}{
		grid-column: span 1;
		margin-bottom: var(--xxs);
	}
`
const Slide = styled(Image)`
	width: 100%;
	margin-right: var(--xs);
`
const Counter = styled.div`
	grid-column: 1/2;
	${mobile}{
		grid-column: span 1;
	}
`
const Caption = styled.div`
	grid-column: 2/3;
	${mobile}{
		grid-column: span 1;
	}
`
const CaptionContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column: span 4;
	grid-column-gap: 26px;
	color: var(--feature);
	${mobile}{
		grid-template-columns: 25% 1fr;
	}
`

ImageSlider.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
	highlight: PropTypes.string,
}

export default ImageSlider