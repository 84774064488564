import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'

const Table = ({ className, content }) => {
	return (
		<Wrap className={className}>
			{content.rows?.map(row=> (
				<Row key={row._key} className='small'>
					<div>{row.key}</div>
					<div>{row.value}</div>
				</Row>
			))}
		</Wrap>
	)
}

const Wrap = styled.div`
	border-top: 1px solid var(--feature);
`
const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-bottom: 1px solid var(--feature);
	padding: 2px 0;
	color: var(--feature);
`
Table.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default Table