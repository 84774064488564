import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import Button from '~components/Button'
import { css } from '@emotion/react'
import Table from '~components/Table'
import ImageSlider from '~components/ImageSlider'
import { mobile } from '~styles/global'
import InlineImage from '~components/InlineImage'
import Space from '~components/Space'
import Accordion from '~components/Accordion'

const serializers = {
	marks: {
		link: props => {
			if(props.mark.linkType === 'internal'){
				return <Link to={resolveLink(props.mark.document)}>{props.children}</Link> 
			} else {
				return <a href={props.mark?.url} target={props.mark.blank ? '_blank' : '_self'} rel='noreferrer'>{props.children}</a>
			}
		},
		highlight: props => {
			return <span className='hightlight'>{props.children}</span>
		}
	},
	types: {
		button: props => {
			return (
				<Button 
					css={css`
							&:last-of-type{
								margin-bottom: 16px;
							}
					`}
					link={props.node.link}
					size={props.node.size}
				>
					{props.node.text}
				</Button>	
			)
		},
		block: props => {
			const { style = 'normal' } = props.node
			
			if (style === 'h2') {
				return (
					<h2 className='h1'>
						{props.children}
					</h2>	
				)
				// Fall back to default handling
			}
			return BlockContent.defaultSerializers.types.block(props)
		},
		expandingSpace: () => {
			return (
				<div 
					css={css`
						flex: 1;
						min-height: var(--large);
						&:first-child{
							min-height: 0;
						}
				`}/>
			)
		},		
		table: props => {
			return (
				<Table content={props.node}/>
			)
		},		
		imageSlider: props => {
			return (
				<ImageSlider content={props.node} css={css`
					margin: var(--m) 0;
					&:last-of-type{
						margin-bottom: 0px;
					}
				`}/>
			)
		},
		imageWithAlt: props => {
			return (
				<InlineImage content={props.node}/>
			)
		},
		space: props => {
			return (
				<Space size={props.node.size}/>
			)
		},
		accordionSection: props => {
			return (
				<Accordion content={props.node}/>
			)
		},
		line: () => {
			return (
				<div css={css`
					height: 1px;
					background-color: var(--feature);
					margin: var(--large) 0;
				`}/>
			)
		}
	}
}

const RichText = ({ content }) => <BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>

RichText.propTypes = {
	content: PropTypes.array,
}

export default RichText
