module.exports = {
	siteUrl: 'https://jackywinterplaces.com',
	previewUrl: 'https://preview-jwplaces.gtsb.io',
	previewWebhook: 'https://webhook.gatsbyjs.com/hooks/data_source/c6c021f9-817e-4efb-bf7e-8e0ff27627c3',
	netlifySiteName: 'jw-places',
	netlifyAppId: 'a024a7b2-fa8c-4333-b7fc-9d4873a39710',
	netlifyBuildHookId: '619b1b4e5c9584554be124ed',
	mailchimpUrl: 'https://work.us20.list-manage.com',
	mailchimpUser: 'b2b9468ba5d766d69fd543e0e',
	mailchimpId: '5226532c72',
	gTag: 'G-XXXXXXXXX'
}